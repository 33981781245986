

import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";
import Api from "../../Redux/common/api";
import { ExportToExcel } from "../component/ExportToExcel";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
function Employee() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pagev = searchParams.get("page");
    const searchData = searchParams.get("search");

    const page = pagev == null ? 1 : pagev;
    const search = searchData == null ? "" : searchData;

    console.log(searchParams);
    console.log(page);
    console.log(searchData);

    const users_list = useSelector((state) =>
        state?.allapi?.users_list ? state?.allapi?.users_list : {}
    );

    const [pages, setPage] = useState(1);

    const listdata = users_list?.data ? users_list?.data : [];

    useEffect(() => {
        getuserDetails();

        return () => { };
    }, [location]);

    const dataSource = [
        {
            key: "1",
            name: "Mike",
            age: 32,
            address: "10 Downing Street",
        },
        {
            key: "2",
            name: "John",
            age: 42,
            address: "10 Downing Street",
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userId, setuserId] = useState("");

    const [statusData, setstatusData] = useState({});
    const [statusData2, setstatusData2] = useState({});
    const [userData, setuserData] = useState({});
    const [userData2, setuserData2] = useState({});
    const [statusDataBox, setstatusDataBox] = useState(false);
    const [statusDataBoxEdit, setstatusDataBoxEdit] = useState(false);
    const [statusDataBox2, setstatusDataBox2] = useState(false);

    const hendleToViewM = (e) => {
        setstatusDataBox(true);
        // setuserData(e);
    };
    const hendleToViewEdit = (e) => {
        setstatusDataBoxEdit(true);
        setuserData(e);
    };
    const hendleToViewM2 = (e) => {
        setstatusDataBox2(true);
        setuserData2(e);
    };

    const hendleTochangedataEdit = (e) => {
        const { name, value } = e.target;

        setuserData({ ...userData, [name]: value });
    };
    const hendleTochangedata = (e) => {
        const { name, value } = e.target;

        setstatusData({ ...statusData, [name]: value });
    };
    const hendleTochangedata2 = (e) => {
        const { name, value } = e.target;

        setstatusData2({ ...statusData2, [name]: value });
    };

    const [images, setImages] = React.useState([]);
    const [images2, setImages2] = React.useState([]);

    const maxNumber = 1;

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);
    };
    const onChange2 = (imageList, addUpdateIndex) => {
        // data for submit

        setImages2(imageList);
    };
    const [startDate2, setStartDate2] = useState(new Date());


    const handleEmailChange = (event) => {
        const newEmail = event;
        // setEmail(newEmail);
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // setIsValidEmail(emailRegex.test(newEmail));
        return emailRegex.test(newEmail)
    };

    const hendleToSubmetUpdateEdit = async () => {
        if (!userData?.name) {
            toast.error("Please enter name");
            return
        }
        if (!userData?.email) {
            toast.error("Please enter email");
            return
        }
        if (!handleEmailChange(userData?.email)) {
            toast.error("Please invalid email");
            return
        }
        if (!userData?.mobile) {
            toast.error("Please enter mobile");
            return
        }
        // if (!userData?.password) {
        //     toast.error("Please enter password");
        //     return
        // }
        if (!userData?.kmrate) {
            toast.error("Please enter kmrate");
            return
        }
        if (!userData?.designation) {
            toast.error("Please enter designation");
            return
        }
        const data = new FormData();
        data.append("page", 1);
        data.append("name", userData?.name);
        data.append("email", userData?.email);
        // data.append("password", userData?.password);
        data.append("mobile", userData?.mobile);
        data.append("id", userData?.id);
        data.append("kmrate", userData?.kmrate);
        // data.append("joindate", moment(startDate2).format("YYYY-MM-DD"));
        // data.append("joindate", userData?.joindate);
        data.append("designation", userData?.designation);
        // data.append("vehicle_number", userData?.vehicle_number);

        // if (images?.length > 0) {
        //     data.append("image", images[0]?.file);
        // }
        // if (images2?.length > 0) {
        //   data.append("driving_licence", images2[0]?.file);
        // }
        const response = await dispatch(allapiAction.updateEmployee(data, page));

        if (response?.status) {
            setstatusDataBoxEdit(false);
            setImages(null);
            setstatusData({ name: "", mobile: "", address: "" });
            // if (page == 1) {
                getuserDetails();
            // } else {
            //     navigate("/employee?page=" + 1 + "&search=" + search);
            // }
        } else {
        }
    };

    const hendleToSubmetUpdate = async () => {
        if (!statusData?.name) {
            toast.error("Please enter name");
            return
        }
        if (!statusData?.email) {
            toast.error("Please enter email");
            return
        }
        if (!handleEmailChange(statusData?.email)) {
            toast.error("Please invalid email");
            return
        }
        if (!statusData?.mobile) {
            toast.error("Please enter mobile");
            return
        }
        if (!statusData?.password) {
            toast.error("Please enter password");
            return
        }
        if (!statusData?.kmrate) {
            toast.error("Please enter kmrate");
            return
        }
        if (!statusData?.designation) {
            toast.error("Please enter designation");
            return
        }
        const data = new FormData();
        data.append("page", 1);
        data.append("name", statusData?.name);
        data.append("email", statusData?.email);
        data.append("password", statusData?.password);
        data.append("mobile", statusData?.mobile);
        data.append("kmrate", statusData?.kmrate);
        data.append("joindate", moment(startDate2).format("YYYY-MM-DD"));
        // data.append("joindate", statusData?.joindate);
        data.append("designation", statusData?.designation);
        // data.append("vehicle_number", statusData?.vehicle_number);

        if (images?.length > 0) {
            data.append("image", images[0]?.file);
        }
        // if (images2?.length > 0) {
        //   data.append("driving_licence", images2[0]?.file);
        // }
        const response = await dispatch(allapiAction.driver_register(data, page));

        if (response?.status) {
            setstatusDataBox(false);
            setImages(null);
            setstatusData({ name: "", mobile: "", address: "" });
            if (page == 1) {
                getuserDetails();
            } else {
                navigate("/employee?page=" + 1 + "&search=" + search);
            }
        } else {
        }
    };

    const getuserDetails = () => {
        if (search) {
            dispatch(
                allapiAction.getUserListseacrch({
                    pageNumber: page,
                    key: search,
                    role: "driver",
                })
            );
        } else {
            dispatch(allapiAction.getuserList({ page, role: "driver" }));
        }
    };

    const hendleToSubmetUpdate2 = () => {
        dispatch(
            allapiAction.deductFoundByAdmin({
                id: userData2?._id,
                amount: statusData2?.amount,

                pageNumber: page,
            })
        );

        setstatusDataBox2(false);
        setstatusData2({ amount: "" });
    };

    const showModal = (e) => {
        console.log(e);
        setuserId(e);
        setIsModalOpen(true);
    };

    const handleOk = async (e, status) => {
        const response = await dispatch(
            allapiAction.deleteEmployee({
                id: userId,
                // active: status,
                // active: 0,
                // pageNumber: page,
            })
        );
        // setuserId("");
        
        if (response?.status) {
            setIsModalOpen(false);
            getuserDetails();
        }

        return () => { };
    };

    const hendleToChangeStatus = (id, status) => {
        dispatch(
            allapiAction.user_inactive({
                _id: id,
                status: status,
                // active: 0,
                pageNumber: page,
            })
        );
        // setuserId("");
        // setIsModalOpen(false);
        return () => { };
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function maskNumber(number) {
        if (number) {
            const numberString = number.toString(); // Convert the number to a string
            const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
            const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
            return maskedPortion + lastTwoDigits;
        }
        // Combine the masked portion and last 2 digits
    }

    const [imagedata, setimagedata] = useState({});
    const [statusDataBoxv, setstatusDataBoxv] = useState(false);


    const hendleToViewMv = (e) => {
        setstatusDataBoxv(true);
        setimagedata(e);
        // setuserData(e);
    };

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sno",
            key: "sno",
            className: "table_first_col",
            render: (value, item, index) => (page - 1) * 10 + index + 1,
        },
        {
            title: "Profile",
            dataIndex: "uid",
            key: "uid",
            className: "table_first_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <div style={{ width: "50px" }}>
                            {item?.image ? (
                                <img
                                    src={URL?.API_BASE_URL + item?.image}
                                    alt=""
                                    style={{ width: "100%" }}
                                    onClick={
                                        () => { hendleToViewMv(URL?.API_BASE_URL + item?.image) }
                                    }
                                />
                            ) : (
                                <img
                                    src={"/male-placeholder-image.webp"}
                                    alt=""
                                    style={{ width: "100%" }}
                                />
                            )}
                        </div>
                    </>
                );
            },
        },

        {
            title: "Employee id",
            dataIndex: "empid",
            key: "empid",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>

                        <span
                            className="cursor-pointer coustomNevc mt-20"
                        // onClick={() => navigate(`/user-details/${item?.id}`)}
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {item?.empid}
                        </span>
                    </>
                );
            },
        },
        {
            title: "User Name",
            dataIndex: "uid",
            key: "uid",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>

                        <span
                            className="cursor-pointer coustomNevc mt-20"
                        // onClick={() => navigate(`/user-details/${item?.id}`)}
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {item?.name}
                        </span>
                    </>
                );
            },
        },
        {
            title: "User Email",
            dataIndex: "email",
            key: "email",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                         
                        <span
                            className="cursor-pointer coustomNevc mt-20"
                        // onClick={() => navigate(`/user-details/${item?.id}`)}
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {item?.email}
                        </span>
                    </>
                );
            },
        },

        {
            title: "Number",
            dataIndex: "number",
            key: "number",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer "
                        // onClick={() => navigate(`/user-details/${item?.uid}`)}
                        >
                            {item?.countrycode}
                            {item?.mobile}
                        </span>
                    </>
                );
            },
        },

        // {
        //   title: "Number",
        //   dataIndex: "number",
        //   key: "number",
        // },
        {
            title: "Designation",
            dataIndex: "designation",
            key: "designation",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {item?.designation}
                        </span>
                    </>
                );
            },
        },
        {
            title: "kmrate",
            dataIndex: "kmrate",
            key: "kmrate",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {item?.kmrate}
                        </span>
                    </>
                );
            },
        },
        

        {
            title: "Joining Date",
            dataIndex: "Join Date",
            key: "Join Date",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {moment(item?.joindate).format(" DD/MM/YYYY ")}
                        </span>
                    </>
                );
            },
        },
        {
            title: "Date",
            dataIndex: "Join Date",
            key: "Join Date",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {moment(item?.created_at).format(" DD/MM/YYYY ")}
                        </span>
                    </>
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            className: "table_action_col actionCall",
            render: (value, item, index) => {
                return (
                    <>
                        <Tooltip title="Task">
                            <div
                                className=" ml-3 empIc  mt-5"
                                onClick={() => navigate(`/employee/task/${item?.id}`)}
                            >
                                {/* Track */}
                                <i className="fa-solid fa-task" style={{ fontSize: "20px" }}></i>
                                <img src="task.png" alt="" style={{ width: "20px" }} />
                            </div>
                        </Tooltip>
                 
                        <Tooltip title="Attendance">
                            <div
                                className=" ml-3 empIc  mt-5"
                                onClick={() => navigate(`/employee/attendance/${item?.id}`)}
                            >
                                {/* Track */}
                                <img src="attendance.png" alt="" style={{ width: "20px" }} />
                            </div>
                        </Tooltip>
                        <Tooltip title="Track">
                            <div
                                className=" ml-10 empIc  mt-5"
                                onClick={() => navigate(`/employee/location/${item?.id}`)}
                            >
                                {/* Track */}
                                <img src="trackicon.png" alt="" style={{ width: "20px" }} />
                            </div>
                        </Tooltip>


                        <Tooltip title="Track Location">
                            <div
                                className="ml-10 empIc mt-5"
                                onClick={() => navigate(`/employee/Maplocation/${item?.id}`)}
                            >
                                <i className="fa-solid fa-location-dot" style={{ fontSize: "20px" }}></i>
                            </div>
                        </Tooltip>
                      
                        <Tooltip title="User Expense">
                            <div
                                className=" ml-10 empIc  mt-5"
                                onClick={() => navigate(`/employee/userexpense/${item?.id}`)}
                            >
                                {/* Track */}
                                <img src="expense.png" alt="" style={{ width: "20px" }} />
                            </div>
                        </Tooltip>

                        <Tooltip title="Edit">
                            <div
                                className="ml-10 empIc mt-5"
                                onClick={() => hendleToViewEdit(item)}
                            >
                                <i className="fa-solid fa-pencil" style={{ fontSize: "20px" }}></i>
                            </div>
                        </Tooltip>
                        <Tooltip title="delete">
                            <div
                                className="ml-10 empIc mt-5"
                                onClick={() => showModal(item?.id)}
                            >
                                <i className="fa-solid fa-trash" style={{ fontSize: "20px" }}></i>
                            </div>
                        </Tooltip>
                         


                    </>
                );
            },
        },
    ];

    const [searchs, setsearch] = useState("");
    const handleChange = (e) => {
        setsearch(e);
        setPage(1);
        navigate("/employee?page=" + 1 + "&search=" + e);
        // if (e) {
        //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
        //   return () => {};
        // } else {
        //   dispatch(allapiAction.getuserList(1));

        //   return () => {};
        // }
        // getuserListSearch
        // &user_id=${data?.user_id}&search=${data?.user_id}
    };

    const hendlePahegi = (e) => {
        setPage(e);

        console.log(location);

        navigate("/employee?page=" + e + "&search=" + search);

        // if (search) {
        //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
        //   return () => {};
        // } else {
        //   dispatch(allapiAction.getuserList(e));

        //   return () => {};
        // }
    };

    const hendletoExportExl = async () => {
        // user_allcontact
        try {
            const token = await localStorage.getItem("admin_token");

            const config = {
                headers: {
                    Authorization: "Bearer " + token,
                },
            };
            // dispatch(loading(true));

            const response = await Api.get(`${URL.getAllUsers}?role=driver`, config);

            if (response?.data?.status) {
                const finaldata =
                    response?.data?.data &&
                    response?.data?.data?.map((datas, i) => {
                        const datass = {
                            name: datas?.name,
                            mobile: datas?.mobile,
                            role: datas?.role,
                            address: datas?.address,
                            // email: datas?.email,
                        };
                        return datass;
                    });
                ExportToExcel(finaldata, "driver");
            } else {
            }
            console.log(response?.data);
            //
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <Modal
                title="delete User"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Are you sure delete</p>
            </Modal>


            <Modal
                title=""
                centered
                open={statusDataBoxv}
                onOk={() => setstatusDataBoxv(false)}
                onCancel={() => setstatusDataBoxv(false)}
                width={500}
                footer={null}

            >
                <div>
                    <img
                        src={imagedata}
                        alt=""
                        style={{ width: "100%" }}

                    />
                </div>
            </Modal>

            <Modal
                title="Add Employee"
                centered
                open={statusDataBox}
                onOk={() => hendleToSubmetUpdate(false)}
                onCancel={() => setstatusDataBox(false)}
                width={500}
            >
                <div>
                    <Row>
                        <Col span={24}>name</Col>
                        <Col span={24}>
                            <Input
                                name="name"
                                placeholder="enter name"
                                value={statusData?.name}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Email</Col>
                        <Col span={24}>
                            <Input
                                name="email"
                                placeholder="enter email"
                                value={statusData?.email}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Mobile</Col>
                        <Col span={24}>
                            <Input
                                name="mobile"
                                placeholder="enter mobile"
                                value={statusData?.mobile}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Password</Col>
                        <Col span={24}>
                            <Input
                                name="password"
                                placeholder="enter password"
                                value={statusData?.password}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Km rate</Col>
                        <Col span={24}>
                            <Input
                                name="kmrate"
                                placeholder="enter kmrate"
                                value={statusData?.kmrate}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Designation</Col>
                        <Col span={24}>
                            <Input
                                name="designation"
                                placeholder="enter designation"
                                value={statusData?.designation}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
                            <lable>Date</lable>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
                            <DatePicker
                                className="form-control"
                                selected={startDate2}
                                onChange={(date) => setStartDate2(date)}
                                placeholderText="Select a date"
                            // showTimeSelect
                            // showTimeSelectOnly
                            // timeIntervals={15}
                            // timeCaption="Time"
                            />
                        </Col>
                        <Col span={24}>Profile Image</Col>
                        <Col span={24}>
                            <ImageUploading
                                // multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <button
                                            className="btn btn-primary btn-sm rounded"
                                            style={isDragging ? { color: "red" } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                            Click
                                        </button>
                                        &nbsp;
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                                <img src={image["data_url"]} alt="" width="100" />
                                                <div className="image-item__btn-wrapper">
                                                    <button
                                                        className="btn btn-primary btn-sm rounded mb-2"
                                                        onClick={() => onImageUpdate(index)}
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        className="btn btn-primary btn-sm rounded"
                                                        onClick={() => onImageRemove(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>
                        </Col>


                    </Row>
                </div>
            </Modal>

            {/* edit */}
            <Modal
                title="Edit Employee"
                centered
                open={statusDataBoxEdit}
                onOk={() => hendleToSubmetUpdateEdit(false)}
                onCancel={() => setstatusDataBoxEdit(false)}
                width={500}
            >
                <div>
                    <Row>
                        <Col span={24}>name</Col>
                        <Col span={24}>
                            <Input
                                name="name"
                                placeholder="enter name"
                                value={userData?.name}
                                onChange={(e) => {
                                    hendleTochangedataEdit(e);
                                }}
                            />
                        </Col>
                        {/* <Col span={24}>Email</Col>
                        <Col span={24}>
                            <Input
                                name="email"
                                placeholder="enter email"
                                value={userData?.email}
                                onChange={(e) => {
                                    hendleTochangedataEdit(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Mobile</Col>
                        <Col span={24}>
                            <Input
                                name="mobile"
                                placeholder="enter mobile"
                                value={userData?.mobile}
                                onChange={(e) => {
                                    hendleTochangedataEdit(e);
                                }}
                            />
                        </Col> */}
                        <Col span={24}>Password</Col>
                        <Col span={24}>
                            <Input
                                name="password"
                                placeholder="enter password"
                                value={userData?.password}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Km rate</Col>
                        <Col span={24}>
                            <Input
                                name="kmrate"
                                placeholder="enter kmrate"
                                value={userData?.kmrate}
                                onChange={(e) => {
                                    hendleTochangedataEdit(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Designation</Col>
                        <Col span={24}>
                            <Input
                                name="designation"
                                placeholder="enter designation"
                                value={userData?.designation}
                                onChange={(e) => {
                                    hendleTochangedataEdit(e);
                                }}
                            />
                        </Col>
                        {/* <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
                            <lable>Date</lable>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
                            <DatePicker
                                className="form-control"
                                selected={startDate2}
                                onChange={(date) => setStartDate2(date)}
                                placeholderText="Select a date"
                            // showTimeSelect
                            // showTimeSelectOnly
                            // timeIntervals={15}
                            // timeCaption="Time"
                            />
                        </Col> */}
                        {/* <Col span={24}>Profile Image</Col>
                        <Col span={24}>
                            <ImageUploading
                                // multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <button
                                            className="btn btn-primary btn-sm rounded"
                                            style={isDragging ? { color: "red" } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                            Click
                                        </button>
                                        &nbsp;
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                                <img src={image["data_url"]} alt="" width="100" />
                                                <div className="image-item__btn-wrapper">
                                                    <button
                                                        className="btn btn-primary btn-sm rounded mb-2"
                                                        onClick={() => onImageUpdate(index)}
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        className="btn btn-primary btn-sm rounded"
                                                        onClick={() => onImageRemove(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>
                        </Col> */}


                    </Row>
                </div>
            </Modal>

            {/* deduct */}
            <Modal
                title="Deduct Amount"
                centered
                open={statusDataBox2}
                onOk={() => hendleToSubmetUpdate2(false)}
                onCancel={() => setstatusDataBox2(false)}
                width={500}
            >
                <div>
                    <Row>
                        <Col span={24}>Amount</Col>
                        <Col span={24}>
                            <Input
                                name="amount"
                                placeholder="enter amount"
                                value={statusData2?.amount}
                                onChange={(e) => {
                                    hendleTochangedata2(e);
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>

            <div className="flex">
                <div>
                    <HeadingBack
                        title={"Employee"}
                        subHeading={`Total ${users_list?.total || 0} Employee`}
                    />
                </div>
                <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
                    <Input
                        type="text"
                        autoComplete="new-off"
                        className="form-control"
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder="Search..."
                    // value={values?.TestCode}
                    />
                   
                </Col>

                <div className="flex1 flex justify-content-end align-items-center">
                    
                    <Tooltip title="Add Employee">
                        <button className="jop_btn ml-5" onClick={() => hendleToViewM()}>
                            Add Employee
                        </button>
                    </Tooltip>
                </div>
            </div>

            <div className="tableResponsive mt-10">
                <Table
                    className={"table antd_table"}
                    dataSource={listdata}
                    columns={columns}
                    pagination={{
                        current: parseInt(page),
                        pageSize: 10,
                        onChange(current, pageSize) {
                            hendlePahegi(current);
                        },
                        hideOnSinglePage: true,
                        total: users_list?.total ? users_list?.total : 0,
                    }}
                />
            </div>
        </div>
    );
}

export default Employee;
