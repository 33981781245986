import React, { useEffect, useState } from "react";
import { Col, Calendar, theme, DatePicker } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import { allapiAction } from "../../Redux/common/action";
import customMarkerIcon from "../assets/images/icons/businessman.png"
import moment from "moment";

// const MapComponent = withScriptjs(
//     withGoogleMap((props) => {
//       const { locations } =props
//       if (!locations || locations.length === 0) {
//         return null; // Handle empty or undefined locations
//       }

//       return (
//         <GoogleMap defaultZoom={17} defaultCenter={{ lat:  parseFloat(locations[0].lat), lng:  parseFloat(locations[0].lang) }}>
//           {locations.map((location, index) => {
//             const latitude = parseFloat(location.lat);
//             const longitude = parseFloat(location.lang);

//             if (isNaN(latitude) || isNaN(longitude)) {
//               console.error(`Invalid location data at index ${index}:`, location);
//               return null; // Skip rendering this marker
//             }

//             return (<Marker key={index}
//              position={{ lat: latitude, lng: longitude }} 
//              onClick={props.onToggleOpen}
//             icon={{
//                 url: customMarkerIcon,
//                 scaledSize: new window.google.maps.Size(60, 60),
//               }}

//             >
//                  <InfoWindow>

//                   <div style={{fontSize: "13px"}}>
//                    <p title={location.address}><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;{location.address.substr(0, 28)}...</p>
//                   <p><i className="fa-solid fa-calendar-days"></i>&nbsp;&nbsp;{location.localtime}</p>
//                    <p><i className="fa-regular fa-clock"></i>&nbsp;&nbsp;
//                     {location.minutes}
//                    </p>
//                    <p>
//                    <i className="fa-solid fa-battery-three-quarters"></i>&nbsp;&nbsp;{location.battery}
//                    </p>
//                 </div>
//               </InfoWindow>
//             </Marker>);
//           })}
//         </GoogleMap>
//       );
//     })
//   );

const MapComponent = withScriptjs(
  withGoogleMap((props) => {
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [indexMarker, setIndexMarker] = useState(0);
    console.log(indexMarker);

    const hendleShow = (index, value) => {
      setIndexMarker(index)
      setSelectedMarker(value)
    }


    const hendlenext = (index, value) => {
      setIndexMarker(index)
      const details = props?.locations[index]
      setSelectedMarker(details)
    }

    useEffect(() => {

      setSelectedMarker(props?.locations?.[0])
      return () => {

      }
    }, [])



    return (
      <GoogleMap defaultZoom={17} defaultCenter={{ lat: parseFloat(props?.locations[0]?.lat), lng: parseFloat(props.locations[0].lang) }}>
        {props?.locations?.map((location, index) => {
          const latitude = parseFloat(location.lat);
          const longitude = parseFloat(location.lang);

          if (isNaN(latitude) || isNaN(longitude)) {
            console.error(`Invalid location data at index ${index}:`, location);
            return null; // Skip rendering this marker
          }

          return (
            <Marker
              key={index}
              position={{ lat: latitude, lng: longitude }}
              onClick={() => hendleShow(index, location)}
              icon={{
                url: customMarkerIcon,
                scaledSize: new window.google.maps.Size(60, 60),
              }}
            >
              {selectedMarker === location && (
                <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                  <div style={{ fontSize: "13px" }}>
                    <p title={location.address}><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;{location.address.substr(0, 28)}...</p>
                    <p><i className="fa-solid fa-calendar-days"></i>&nbsp;&nbsp;{location.localtime}</p>
                    <p><i className="fa-regular fa-clock"></i>&nbsp;&nbsp;{location.minutes}</p>
                    <p><i className="fa-solid fa-battery-three-quarters"></i>&nbsp;&nbsp;{location.battery}</p>
                    <button className={indexMarker == 0 ? "status_inactive1" : "status_active1"} onClick={() => { hendlenext(index - 1) }}>Previous</button>
                    <button className={indexMarker + 1 == props?.locations?.length ? "status_inactive1" : "status_active1"} onClick={() => { hendlenext(index + 1) }}>Next</button>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
    );
  })
);

function MapuserLocation() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const users_contact_list = useSelector((state) =>
    state?.allapi?.map_data ? state?.allapi?.map_data : []
  );

console.log(users_contact_list);

  const listdata = users_contact_list ? users_contact_list : [];
  const [totalDistance, settotalDistance] = useState(0)
console.log("totalDistance",totalDistance);
  useEffect(() => {
    var today = moment().format('YYYY-MM-DD');
    console.log(today);
    getdata()
    return () => { };
  }, [date]);

  const getdata = async()=>{
  const response = await  dispatch(allapiAction.usercontactdate({ page: date ? null : 1, user_id: id, date }));
  console.log(response);

  if (response?.status) {
    settotalDistance(response?.totalDistance)
  } else {
    settotalDistance(0)
  }
  }

  const wrapperStyle = {
    width: 500,

  };
  const onPanelChange = async (value) => {

    console.log(value);
    setDate(value.format('YYYY-MM-DD'))
  };


  const onChange = (date, dateString) => {
    setDate(dateString)
  };


  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Location"}
            subHeading={`Total ${users_contact_list?.total || 0} Location`}
          /> 
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5 ml-5"></Col>
        <div className="flex1 flex justify-content-end align-items-center"></div>
      </div>

      <div >
        <div style={wrapperStyle}>
          <DatePicker defaultValue={moment()} onChange={onChange} /><span style={{color:"red"}}>  Total Distance : {totalDistance?.toFixed(2)} km</span>
          {/* <Calendar fullscreen={false} onSelect={onPanelChange} /> */}
        </div>
      </div>

      <div className="tableResponsive mt-10" >
        {listdata?.length > 0 ? <MapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBb3j8Aiv60CadZ_wJS_5wg2KBO6081a_k&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          locations={listdata}
        /> :
          <div style={{ textAlign: "center", alignItems: "center", marginTop: "100px" }}>
            <h4>No Data Found</h4>
          </div>}
      </div>


      <div className="tableResponsive mt-40" >
{

  listdata?.length > 0 && listdata?.map((data,i)=>{
    return(
      <div className="locationbox">
          <div className="locationbox1">
            <div className="locdot"></div>
            <p><i className="fa-solid fa-location-crosshairs"></i> {data?.address}</p>
            <p><i className="fa-regular fa-clock"></i> {data?.minutes} <span style={{color:"blue"}}>minutes</span></p>
            <p><i className="fa-solid fa-calendar-days"></i> {data?.localtime}</p>
          </div>


        </div>
    )
  })
}
       
      </div>
    </div>
  );
}

export default MapuserLocation;
