




import React, { useEffect, useState } from "react";
import { Col, Input, Table, Tooltip, Row, Modal } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import { ExportToExcel } from "../component/ExportToExcel";
import { URL } from "../../Redux/common/url";
import Api from "../../Redux/common/api";
import moment from "moment";
function UserExpense() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [statusDataBox2, setstatusDataBox2] = useState(false);
    const [userData2, setuserData2] = useState({});
    const [statusData2, setstatusData2] = useState({});


    const users_contact_list = useSelector((state) =>
        state?.allapi?.get_attendence_data ? state?.allapi?.get_attendence_data : {}
    );

    console.log(users_contact_list);
    console.log(id);

    const listdata = users_contact_list?.data ? users_contact_list?.data : [];


    const hendleToViewM2 = (e) => {
        setstatusDataBox2(true);
        setuserData2(e);
        setstatusData2(e);
    };


    const hendleToSubmetUpdate2 = async (id, status) => {
        const response = await dispatch(
            allapiAction.updateexpense({
                id: statusData2?.id,
                // approve: status,
                status: statusData2?.status,
                // active: 0,
                pageNumber: page,
            })
        );
        if (response?.status) {
            getuserDetails();
            setstatusDataBox2(false);
            setstatusData2({});
        }

        // setuserId("");
        // setIsModalOpen(false);
        return () => { };
    };


    const hendleToChangeStatus = async (id, status) => {
        const response = await dispatch(
            allapiAction.updateexpense({
                id: id,
                approve: status,
                // active: 0,
                pageNumber: page,
            })
        );
        if (response?.status) {
            getuserDetails();
        }

        // setuserId("");
        // setIsModalOpen(false);
        return () => { };
    };

    const hendleTochangedata2 = (e) => {
        const { name, value } = e.target;

        setstatusData2({ ...statusData2, [name]: value });
    };

    const dataSource = [
        {
            key: "1",
            name: "Mike",
            age: 32,
            address: "10 Downing Street",
        },
        {
            key: "2",
            name: "John",
            age: 42,
            address: "10 Downing Street",
        },
    ];


    const columns = [
        {
            title: "S.No.",
            dataIndex: "sno",
            key: "sno",
            className: "table_first_col",
            render: (value, item, index) => (page - 1) * 10 + index + 1,
        },
        {
            title: "Profile",
            dataIndex: "uid",
            key: "uid",
            className: "table_first_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <div style={{ width: "50px" }}>
                            {item?.employee?.image ? (
                                <img
                                    src={URL?.API_BASE_URL + item?.employee?.image}
                                    alt=""
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                <img
                                    src={"/male-placeholder-image.webp"}
                                    alt=""
                                    style={{ width: "100%" }}
                                />
                            )}
                        </div>
                    </>
                );
            },
        },

        // {
        //     title: "Employee id",
        //     dataIndex: "empid",
        //     key: "empid",
        //     className: "table_action_col",
        //     render: (value, item, index) => {
        //         return (
        //             <>

        //                 <span
        //                     className="cursor-pointer coustomNevc mt-20"
        //                 // onClick={() => navigate(`/user-details/${item?.id}`)}
        //                 // onClick={() => navigate(`/users/contact/${item?.id}`)}
        //                 >
        //                     {item?.empid}
        //                 </span>
        //             </>
        //         );
        //     },
        // },
        {
            title: "User Name",
            dataIndex: "uid",
            key: "uid",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>

                        <span
                            className="cursor-pointer coustomNevc mt-20"
                        // onClick={() => navigate(`/user-details/${item?.id}`)}
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {item?.employee?.name}
                        </span>
                    </>
                );
            },
        },


        {
            title: "Approve",
            dataIndex: "approve",
            key: "approve",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>

                        {/* <span
                            className="cursor-pointer "
                        // onClick={() => navigate(`/user-details/${item?.uid}`)}
                        >

                            {item?.approve == "0" ? "Unapprove" : "Approve"}

                        </span> */}
                        <Tooltip title={item?.status}>
                            <button
                                className={
                                    item?.status !== "pending"
                                        ? "success-button ml-3"
                                        : "delete-button ml-3"
                                }
                                onClick={() =>
                                    hendleToViewM2(
                                        item,
                                        item?.approve == "0" ? "1" : "0"
                                    )
                                }
                            >
                                {item?.status}
                            </button>
                        </Tooltip>
                    </>
                );
            },
        },

        {
            title: "Courier",
            dataIndex: "courier",
            key: "courier",
        },
        {
            title: "Bustrain fair",
            dataIndex: "bustrainfair",
            key: "bustrainfair",
        },
        {
            title: "Food",
            dataIndex: "food",
            key: "food",
        },
        {
            title: "Hotel stay",
            dataIndex: "hotelstay",
            key: "hotelstay",
        },
        {
            title: "Internet Bill",
            dataIndex: "internetbill",
            key: "internetbill",
        },
        {
            title: "Laundry",
            dataIndex: "laundry",
            key: "laundry",
        },
        {
            title: "Local conveyance",
            dataIndex: "localconveyance",
            key: "localconveyance",
        },
        {
            title: "Other charges",
            dataIndex: "othercharges",
            key: "othercharges",
        },
        {
            title: "Phone Bill",
            dataIndex: "phonebill",
            key: "phonebill",
        },
        {
            title: "Photo Copy",
            dataIndex: "photocopy",
            key: "photocopy",
        },
        {
            title: "Toll Tax",
            dataIndex: "tolltax",
            key: "tolltax",
        },
        {
            title: "Total Ride Km",
            dataIndex: "totalridekm",
            key: "totalridekm",
        },
        {
            title: "Market Visit",
            dataIndex: "marketvisit",
            key: "marketvisit",
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
        },
        {
            title: "Station",
            dataIndex: "station",
            key: "station",
        },
        {
            title: "Open Reading",
            dataIndex: "openreading",
            key: "openreading",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Vehicle",
            dataIndex: "vehicle",
            key: "vehicle",
        },


        {
            title: "Date",
            dataIndex: "Join Date",
            key: "Join Date",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {moment(item?.created_at).format(" DD/MM/YYYY ")}
                        </span>
                    </>
                );
            },
        },

    ];

    const getuserDetails = () => {
        // if (search) {
        //     dispatch(
        //         allapiAction.getexpensefilter({
        //             page: page,
        //             key: search,
        //             role: "driver",
        //         })
        //     );
        // } else {
        dispatch(allapiAction.getempexpense({ page: page, user_id: id }));
        // }
    };

    useEffect(() => {


        dispatch(allapiAction.getempexpense({ page: 1, user_id: id }));


        return () => { };
    }, []);

    const [page, setPage] = useState(1);
    const [search, setsearch] = useState("");
    const handleChange = (e) => {
        console.log(e);
        setsearch(e);
        setPage(1);
        if (e) {
            dispatch(
                allapiAction.getempexpense({ page: 1, user_id: id, search: e })
            );
            return () => { };
        } else {
            dispatch(allapiAction.getempexpense({ page: 1, user_id: id }));

            return () => { };
        }
        // usercontactser
        // &user_id=${data?.user_id}&search=${data?.user_id}
    };

    const hendlePahegi = (e) => {
        setPage(e);

        if (search) {
            dispatch(
                allapiAction.getempexpense({ page: e, user_id: id, search: search })
            );
            return () => { };
        } else {
            dispatch(allapiAction.getempexpense({ page: e, user_id: id }));

            return () => { };
        }
    };
    const fileName = "Application report";

    const hendletoExportExl = async () => {
        // user_allcontact
        try {
            const token = await localStorage.getItem("admin_token");

            const config = {
                headers: {
                    Authorization: "Bearer " + token,
                },
            };
            // dispatch(loading(true));

            const response = await Api.get(
                `${URL.user_allcontact}?user_id=${id}`,
                config
            );

            if (response?.data?.status) {
                ExportToExcel(
                    response?.data?.data?.contact,
                    response?.data?.data?.user?.name
                );
            } else {
            }
            console.log(response?.data);
            //
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div>
            <Modal
                title="Status"
                centered
                open={statusDataBox2}
                onOk={() => hendleToSubmetUpdate2(false)}
                onCancel={() => setstatusDataBox2(false)}
                width={500}
            >
                <div>
                    <Row>
                        <Col span={24}>Amount</Col>
                        <Col span={24}>
                            {/* <Input
                                name="amount"
                                placeholder="enter amount"
                                value={statusData2?.amount}
                                onChange={(e) => {
                                    hendleTochangedata2(e);
                                }}
                            /> */}
                            <select
                                className="form-control bg-transparent"
                                name="status"
                                value={statusData2?.status}
                                onChange={(e) => {
                                    hendleTochangedata2(e);
                                }}
                            >
                                {/* <option value={""}>All </option> */}

                                <option value={"pending"}>Pending</option>
                                <option value={"approve"}>Approve</option>
                                <option value={"reject"}>Reject</option>

                            </select>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <div className="flex">
                <div>
                    <HeadingBack
                        title={"Expense"}
                        subHeading={`Total ${users_contact_list?.total || 0} Expense`}
                    />
                </div>
                <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
                    {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
                    {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
                </Col>
                <div className="flex1 flex justify-content-end align-items-center">
                    {/* <Tooltip title="Add">
            <span className="jop_btn" onClick={() => hendletoExportExl()}>
              Export
              
            </span>
          </Tooltip> */}
                </div>
            </div>

            <div className="tableResponsive mt-10">
                <Table
                    dataSource={listdata}
                    columns={columns}
                    pagination={{
                        current: page,
                        pageSize: 15,
                        onChange(current, pageSize) {
                            hendlePahegi(current);
                        },
                        hideOnSinglePage: true,
                        total: users_contact_list?.total ? users_contact_list?.total : 0,
                    }}
                />
            </div>
        </div>
    );
}

export default UserExpense;
