import React, { useEffect } from "react";
import { Col, Row } from "antd";
import Boxes from "./Boxes";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import axios from "axios";

import { Chart } from "react-google-charts";
import Boxes2 from "./Boxes2";
import UserrTable from "./UserrTable";
import CenteralHealthLine from "./CenteralHealthLineChart/CenteralHealthLine";

function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allapiAction.dashboard({}));
    // dispatch(allapiAction.getnotificationAdminall({}));
    // dispatch(allapiAction.dashboardChart({}));

    return () => {};
  }, []);

  const deshboard_state = useSelector((state) =>
    state?.allapi?.deshboard_state ? state?.allapi?.deshboard_state : {}
  );
  const data2 = [
    ["Year", "Sales", "Expenses"],
    ["Monday", 20000, 30000],
    ["Tuesday", 35000, 15000],
    ["Wednesday", 38000, 14000],
    ["Thursday", 25000, 35000],
    ["Friday", 34000, 22000],
    ["Saturday", 42000, 34000],
    ["Sunday", 18000, 38000],
  ];
  const data = deshboard_state;

  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

  console.log("3" + "3" - "3");

  return (
    <div>
      <Row gutter={[20, 20]} type="flex">
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className=" p-10">
           <h3>Welcome To Dashboard</h3>
            <Boxes2 />
            
          </div>
        </Col>
        {/* <Col lg={6} md={24} sm={24} xs={24}>
          <div>
            <Boxes />
          </div>
        </Col> */}
      </Row>
    </div>
  );
}

export default Home;
