import React from "react";
import User from "../Component/User/User";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import LayoutMain from "../Component/Layout";
import { GetToken } from "../Component/utils";
import Contact from "../Component/User/Contact";
import Category from "../Component/Category/Category";
import Quiz from "../Component/Quiz/Quiz";
import Question from "../Component/Question/Question";
import TestTable from "../Component/TestTable/TestTable";
import Bettel from "../Component/Bettel/Bettel";
import BettelReq from "../Component/Bettel/BettelReq";
import WithdrawalRequest from "../Component/WithdrawalRequest/WithdrawalRequest";
import Kyc from "../Component/Kyc/Kyc";
import Transaction from "../Component/Transaction/Transaction";
import UserDetails from "../Component/User/UserDetails";
import Bettalview from "../Component/Bettel/Bettalview";
import Setting from "../Component/Setting/Setting";
import BettalviewWin from "../Component/Bettel/BettalviewWin";
import AccountList from "../Component/Account/Account";
import PointRequest from "../Component/Account/PointRequest";
import Message from "../Component/Message/Message";
import SubCategory from "../Component/Category/SubCategory";
import Panchayat from "../Component/Category/Paynchayt";
import Pit from "../Component/Category/Pit";
import Composit from "../Component/Category/Composit";
import WasteInPit from "../Component/Category/WasteInPit";
import Donation from "../Component/Category/Donation";
import Spendature from "../Component/Category/Spendature";
import SaleWaste from "../Component/Category/SaleWaste";
import StoreWaste from "../Component/Category/StoreWaste";
import DryStoreWaste from "../Component/Category/DryStoreWaste";
import Driver from "../Component/User/Driver";
import PitHistory from "../Component/Category/PitHistory";
import SliderCom from "../Component/Category/SliderCom";
import ContactCom from "../Component/Category/Contect";
import Employee from "../Component/User/Employee";
import UserLocation from "../Component/User/UserLocation";
import MapuserLocation from "../Component/User/MapuserLocation"
import AttendanceUser from "../Component/User/AttendanceUser";
import Expense from "../Component/Expense/Expense";
import UserExpense from "../Component/Expense/UserExpense";
import Task from "../Component/User/Task";

function PageRouter() {
  function RequireAuth({ children }) {
    const token = GetToken();
    return token ? children : <Navigate to="/login" replace />;
  }
  function AlreadyLogin({ children }) {
    const token = GetToken();
    return token ? <Navigate to="/" replace /> : children;
  }
  return (
    <div>
      <Routes>
        {/* <Route path="/forgot-password" element={<AlreadyLogin> <ForgotPassword /></AlreadyLogin>} /> */}
        <Route
          path="/login"
          element={
            <AlreadyLogin>
              <Login />
            </AlreadyLogin>
          }
        />
        <Route
          element={
            <RequireAuth>
              {" "}
              <LayoutMain />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<User />} />
          {/* <Route path="/user-details/:id" element={<UserDetails />} /> */}
          {/* <Route path="/battle-view/:id" element={<Bettalview />} /> */}
          {/* <Route path="/battle-view-win/:id" element={<BettalviewWin />} /> */}
          {/* <Route path="/users/contact/:id" element={<Contact />} /> */}
          <Route path="/employee/location/:id" element={<UserLocation />} />
          <Route path="/employee/attendance/:id" element={<AttendanceUser />} />
          <Route path="/employee/task/:id" element={<Task />} />
          <Route path="/employee/Maplocation/:id" element={<MapuserLocation />} />
          <Route path="/employee/userexpense/:id" element={<UserExpense />} />
          <Route path="/employee" element={<Employee />} />
          <Route path="/expense" element={<Expense />} />
          {/* <Route path="/suggestion" element={<Bettel />} /> */}
          {/* <Route path="/setting" element={<Setting />} /> */}
          {/* <Route path="/account-list" element={<AccountList />} /> */}
          {/* <Route path="/point-request" element={<PointRequest />} /> */}
          {/* <Route path="/transaction" element={<Transaction />} /> */}
          {/* <Route path="/kyc-pending" element={<Kyc />} /> */}
          {/* <Route path="/winner-request" element={<BettelReq />} /> */}
          {/* <Route path="/withdrawal-request" element={<WithdrawalRequest />} /> */}
          {/* <Route path="/message" element={<Message />} /> */}
          {/* <Route path="/category" element={<Category />} /> */}
          {/* <Route path="/sub-category" element={<SubCategory />} /> */}
          {/* <Route path="/panchayat" element={<Panchayat />} /> */}
          {/* <Route path="/pit" element={<Pit />} /> */}
          {/* <Route path="/composit" element={<Composit />} /> */}
          {/* <Route path="/waste-in-pit" element={<WasteInPit />} /> */}
          {/* <Route path="/pit-history/:id" element={<PitHistory />} /> */}
          {/* <Route path="/donation" element={<Donation />} /> */}
          {/* <Route path="/expenditure" element={<Spendature />} /> */}
          {/* <Route path="/slider" element={<SliderCom />} /> */}
          {/* <Route path="/driver" element={<Driver />} /> */}
         
          {/* <Route path="/contact" element={<ContactCom />} /> */}
          {/* <Route path="/sale-waste" element={<SaleWaste />} /> */}
          {/* <Route path="/store-waste" element={<StoreWaste />} /> */}
          {/* <Route path="/dry-store-waste" element={<DryStoreWaste />} /> */}
          {/* <Route path="/faq-category" element={<FaqCategory />} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default PageRouter;
