



import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip, DatePicker } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";
import Api from "../../Redux/common/api";
import { ExportToExcel } from "../component/ExportToExcel";
import { toast } from "react-toastify";

const { RangePicker } = DatePicker;

function Expense() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pagev = searchParams.get("page");
    const searchData = searchParams.get("search");
    const [date, setDate] = useState([]);

    const page = pagev == null ? 1 : pagev;
    const search = searchData == null ? "" : searchData;

    // console.log(searchParams);
    // console.log(page);
    // console.log(searchData);

    const users_list = useSelector((state) =>
        state?.allapi?.users_expense_list ? state?.allapi?.users_expense_list : {}
    );

    console.log(users_list);


    const [pages, setPage] = useState(1);

    const listdata = users_list?.data?.data ? users_list?.data?.data : [];


    const dataSource = [
        {
            key: "1",
            name: "Mike",
            age: 32,
            address: "10 Downing Street",
        },
        {
            key: "2",
            name: "John",
            age: 42,
            address: "10 Downing Street",
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userId, setuserId] = useState("");

    const [statusData, setstatusData] = useState({});
    const [statusData2, setstatusData2] = useState({});
    const [userData, setuserData] = useState({});
    const [userData2, setuserData2] = useState({});
    const [statusDataBox, setstatusDataBox] = useState(false);
    const [statusDataBox2, setstatusDataBox2] = useState(false);


    // console.log(statusData2);
    const hendleToViewM = (e) => {
        setstatusDataBox(true);
        // setuserData(e);
    };
    const hendleToViewM2 = (e) => {
        setstatusDataBox2(true);
        setuserData2(e);
        setstatusData2(e);
    };

    const hendleTochangedata = (e) => {
        const { name, value } = e.target;

        setstatusData({ ...statusData, [name]: value });
    };
    const hendleTochangedata2 = (e) => {
        const { name, value } = e.target;

        setstatusData2({ ...statusData2, [name]: value });
    };

    const [images, setImages] = React.useState([]);
    const [images2, setImages2] = React.useState([]);

    const maxNumber = 1;

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);
    };
    const onChange2 = (imageList, addUpdateIndex) => {
        // data for submit

        setImages2(imageList);
    };
    const [startDate2, setStartDate2] = useState(new Date());


    const handleEmailChange = (event) => {
        const newEmail = event;
        // setEmail(newEmail);
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // setIsValidEmail(emailRegex.test(newEmail));
        return emailRegex.test(newEmail)
    };

    const hendleToSubmetUpdate = async () => {
        if (!statusData?.name) {
            toast.error("Please enter name");
            return
        }
        if (!statusData?.email) {
            toast.error("Please enter email");
            return
        }
        if (!handleEmailChange(statusData?.email)) {
            toast.error("Please invalid email");
            return
        }
        if (!statusData?.mobile) {
            toast.error("Please enter mobile");
            return
        }
        if (!statusData?.password) {
            toast.error("Please enter password");
            return
        }
        if (!statusData?.kmrate) {
            toast.error("Please enter kmrate");
            return
        }
        if (!statusData?.designation) {
            toast.error("Please enter designation");
            return
        }
        const data = new FormData();
        data.append("page", 1);
        data.append("name", statusData?.name);
        data.append("email", statusData?.email);
        data.append("password", statusData?.password);
        data.append("mobile", statusData?.mobile);
        data.append("kmrate", statusData?.kmrate);
        data.append("joindate", moment(startDate2).format("YYYY-MM-DD"));
        // data.append("joindate", statusData?.joindate);
        data.append("designation", statusData?.designation);
        // data.append("vehicle_number", statusData?.vehicle_number);

        if (images?.length > 0) {
            data.append("image", images[0]?.file);
        }
        // if (images2?.length > 0) {
        //   data.append("driving_licence", images2[0]?.file);
        // }
        const response = await dispatch(allapiAction.driver_register(data, page));

        if (response?.status) {
            setstatusDataBox(false);
            setstatusData({ name: "", mobile: "", address: "" });
            if (page == 1) {
                getuserDetails();
            } else {
                navigate("/expense?page=" + 1 + "&search=" + search);
            }
        } else {
        }
    };

    const getuserDetails = () => {
        console.log(date[0]);
        if (search) {
            const obj = { page: page, key: search, role: "driver" }


            if (date) {

                obj.startdate = date[0];
                obj.enddate = date[1];
            }
            dispatch(
                allapiAction.getexpensefilter(obj)
            );
        } else {
            const obj = { page: page, role: "driver" }

            if (date.length > 0) {
                obj.startdate = date[0];
                obj.enddate = date[1];
            }

            dispatch(allapiAction.getexpense(obj));
        }
    };

    useEffect(() => {
        // console.log(date);
        getuserDetails();

        return () => { };
    }, [location, date]);


    const hendleToSubmetUpdate2v = () => {
        dispatch(
            allapiAction.deductFoundByAdmin({
                id: userData2?._id,
                status: statusData2?.amount,

                pageNumber: page,
            })
        );

        setstatusDataBox2(false);
        setstatusData2({ amount: "" });
    };

    const hendleToSubmetUpdate2 = async (id, status) => {
        const response = await dispatch(
            allapiAction.updateexpense({
                id: statusData2?.id,
                // approve: status,
                status: statusData2?.status,
                // active: 0,
                pageNumber: page,
            })
        );
        if (response?.status) {
            getuserDetails();
            setstatusDataBox2(false);
            setstatusData2({});
        }

        // setuserId("");
        // setIsModalOpen(false);
        return () => { };
    };

    const showModal = (e) => {
        console.log(e);
        setuserId(e);
        setIsModalOpen(true);
    };

    const handleOk = async (e, status) => {
        const response = await dispatch(
            allapiAction.user_inactive({
                id: e,
                active: status,
                // active: 0,
                // pageNumber: page,
            })
        );
        // setuserId("");
        // setIsModalOpen(false);

        if (response?.status) {
            getuserDetails();
        }

        return () => { };
    };



    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function maskNumber(number) {
        if (number) {
            const numberString = number.toString(); // Convert the number to a string
            const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
            const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
            return maskedPortion + lastTwoDigits;
        }
        // Combine the masked portion and last 2 digits
    }

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sno",
            key: "sno",
            className: "table_first_col",
            render: (value, item, index) => (page - 1) * 10 + index + 1,
        },
        {
            title: "Profile",
            dataIndex: "uid",
            key: "uid",
            className: "table_first_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <div style={{ width: "50px" }}>
                            {item?.employee?.image ? (
                                <img
                                    src={URL?.API_BASE_URL + item?.employee?.image}
                                    alt=""
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                <img
                                    src={"/male-placeholder-image.webp"}
                                    alt=""
                                    style={{ width: "100%" }}
                                />
                            )}
                        </div>
                    </>
                );
            },
        },

        // {
        //     title: "Employee id",
        //     dataIndex: "empid",
        //     key: "empid",
        //     className: "table_action_col",
        //     render: (value, item, index) => {
        //         return (
        //             <>

        //                 <span
        //                     className="cursor-pointer coustomNevc mt-20"
        //                 // onClick={() => navigate(`/user-details/${item?.id}`)}
        //                 // onClick={() => navigate(`/users/contact/${item?.id}`)}
        //                 >
        //                     {item?.empid}
        //                 </span>
        //             </>
        //         );
        //     },
        // },
        {
            title: "User Name",
            dataIndex: "uid",
            key: "uid",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>

                        <span
                            className="cursor-pointer coustomNevc mt-20"
                        // onClick={() => navigate(`/user-details/${item?.id}`)}
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {item?.employee?.name}
                        </span>
                    </>
                );
            },
        },


        {
            title: "Approve",
            dataIndex: "approve",
            key: "approve",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>

                        {/* <span
                            className="cursor-pointer "
                        // onClick={() => navigate(`/user-details/${item?.uid}`)}
                        >

                            {item?.approve == "0" ? "Unapprove" : "Approve"}

                        </span> */}
                        <Tooltip title={item?.status}>
                            <button
                                className={
                                    item?.status !== "pending"
                                        ? "success-button ml-3"
                                        : "delete-button ml-3"
                                }
                                onClick={() =>
                                    hendleToViewM2(
                                        item,
                                        item?.approve == "0" ? "1" : "0"
                                    )
                                }
                            >
                                {item?.status}
                            </button>
                        </Tooltip>
                    </>
                );
            },
        },

        {
            title: "Courier",
            dataIndex: "courier",
            key: "courier",
        },
        {
            title: "Bustrain fair",
            dataIndex: "bustrainfair",
            key: "bustrainfair",
        },
        {
            title: "Food",
            dataIndex: "food",
            key: "food",
        },
        {
            title: "Hotel stay",
            dataIndex: "hotelstay",
            key: "hotelstay",
        },
        {
            title: "Internet Bill",
            dataIndex: "internetbill",
            key: "internetbill",
        },
        {
            title: "Laundry",
            dataIndex: "laundry",
            key: "laundry",
        },
        {
            title: "Local conveyance",
            dataIndex: "localconveyance",
            key: "localconveyance",
        },
        {
            title: "Other charges",
            dataIndex: "othercharges",
            key: "othercharges",
        },
        {
            title: "Phone Bill",
            dataIndex: "phonebill",
            key: "phonebill",
        },
        {
            title: "Photo Copy",
            dataIndex: "photocopy",
            key: "photocopy",
        },
        {
            title: "Toll Tax",
            dataIndex: "tolltax",
            key: "tolltax",
        },
        {
            title: "Total Ride Km",
            dataIndex: "totalridekm",
            key: "totalridekm",
        },
        {
            title: "Market Visit",
            dataIndex: "marketvisit",
            key: "marketvisit",
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
        },
        {
            title: "Station",
            dataIndex: "station",
            key: "station",
        },
        {
            title: "Open Reading",
            dataIndex: "openreading",
            key: "openreading",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Vehicle",
            dataIndex: "vehicle",
            key: "vehicle",
        },


        {
            title: "Date",
            dataIndex: "Join Date",
            key: "Join Date",
            className: "table_action_col",
            render: (value, item, index) => {
                return (
                    <>
                        {/* <Tooltip title="Contact"> */}
                        <span
                            className="cursor-pointer"
                        // onClick={() => navigate(`/users/contact/${item?.id}`)}
                        >
                            {moment(item?.created_at).format(" DD/MM/YYYY ")}
                        </span>
                    </>
                );
            },
        },

    ];

    const [searchs, setsearch] = useState("");
    const handleChange = (e) => {
        setsearch(e);
        setPage(1);
        navigate("/expense?page=" + 1 + "&search=" + e);
        // if (e) {
        //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
        //   return () => {};
        // } else {
        //   dispatch(allapiAction.getuserList(1));

        //   return () => {};
        // }
        // getuserListSearch
        // &user_id=${data?.user_id}&search=${data?.user_id}
    };

    const hendlePahegi = (e) => {
        setPage(e);

        console.log(location);

        navigate("/expense?page=" + e + "&search=" + search);

        // if (search) {
        //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
        //   return () => {};
        // } else {
        //   dispatch(allapiAction.getuserList(e));

        //   return () => {};
        // }
    };

    const hendletoExportExl = async () => {
        // user_allcontact
        try {
            const token = await localStorage.getItem("admin_token");

            const config = {
                headers: {
                    Authorization: "Bearer " + token,
                },
            };
            // dispatch(loading(true));

            const response = await Api.get(`${URL.getAllUsers}?role=driver`, config);

            if (response?.data?.status) {
                const finaldata =
                    response?.data?.data &&
                    response?.data?.data?.map((datas, i) => {
                        const datass = {
                            name: datas?.name,
                            mobile: datas?.mobile,
                            role: datas?.role,
                            address: datas?.address,
                            // email: datas?.email,
                        };
                        return datass;
                    });
                ExportToExcel(finaldata, "driver");
            } else {
            }
            console.log(response?.data);
            //
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <Modal
                title="delete User"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Are you sure delete</p>
            </Modal>

            <Modal
                title="Add Employee"
                centered
                open={statusDataBox}
                onOk={() => hendleToSubmetUpdate(false)}
                onCancel={() => setstatusDataBox(false)}
                width={500}
            >
                <div>
                    <Row>
                        <Col span={24}>name</Col>
                        <Col span={24}>
                            <Input
                                name="name"
                                placeholder="enter name"
                                value={statusData?.name}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Email</Col>
                        <Col span={24}>
                            <Input
                                name="email"
                                placeholder="enter email"
                                value={statusData?.email}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Mobile</Col>
                        <Col span={24}>
                            <Input
                                name="mobile"
                                placeholder="enter mobile"
                                value={statusData?.mobile}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Password</Col>
                        <Col span={24}>
                            <Input
                                name="password"
                                placeholder="enter password"
                                value={statusData?.password}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Km rate</Col>
                        <Col span={24}>
                            <Input
                                name="kmrate"
                                placeholder="enter kmrate"
                                value={statusData?.kmrate}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col span={24}>Designation</Col>
                        <Col span={24}>
                            <Input
                                name="designation"
                                placeholder="enter designation"
                                value={statusData?.designation}
                                onChange={(e) => {
                                    hendleTochangedata(e);
                                }}
                            />
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
                            <lable>Date</lable>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
                            <DatePicker
                                className="form-control"
                                selected={startDate2}
                                onChange={(date) => setStartDate2(date)}
                                placeholderText="Select a date"
                            // showTimeSelect
                            // showTimeSelectOnly
                            // timeIntervals={15}
                            // timeCaption="Time"
                            />
                        </Col>
                        <Col span={24}>Profile Image</Col>
                        <Col span={24}>
                            <ImageUploading
                                // multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <button
                                            className="btn btn-primary btn-sm rounded"
                                            style={isDragging ? { color: "red" } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                            Click
                                        </button>
                                        &nbsp;
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                                <img src={image["data_url"]} alt="" width="100" />
                                                <div className="image-item__btn-wrapper">
                                                    <button
                                                        className="btn btn-primary btn-sm rounded mb-2"
                                                        onClick={() => onImageUpdate(index)}
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        className="btn btn-primary btn-sm rounded"
                                                        onClick={() => onImageRemove(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>
                        </Col>


                    </Row>
                </div>
            </Modal>

            {/* deduct */}
            <Modal
                title="Status"
                centered
                open={statusDataBox2}
                onOk={() => hendleToSubmetUpdate2(false)}
                onCancel={() => setstatusDataBox2(false)}
                width={500}
            >
                <div>
                    <Row>
                        <Col span={24}>Amount</Col>
                        <Col span={24}>
                            {/* <Input
                                name="amount"
                                placeholder="enter amount"
                                value={statusData2?.amount}
                                onChange={(e) => {
                                    hendleTochangedata2(e);
                                }}
                            /> */}
                            <select
                                className="form-control bg-transparent"
                                name="status"
                                value={statusData2?.status}
                                onChange={(e) => {
                                    hendleTochangedata2(e);
                                }}
                            >
                                {/* <option value={""}>All </option> */}

                                <option value={"pending"}>Pending</option>
                                <option value={"approve"}>Approve</option>
                                <option value={"reject"}>Reject</option>

                            </select>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <div className="flex">
                <div>
                    <HeadingBack
                        title={"Expense"}
                        subHeading={`Total ${users_list?.total || 0} Expense`}
                    />
                </div>
                <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
                    {/* <Input
                        type="text"
                        autoComplete="new-off"
                        className="form-control"
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder="Search..."
                    // value={values?.TestCode}
                    /> */}
                    <select
                        className="form-control bg-transparent"
                        onChange={(e) => {
                            handleChange(e.target.value);
                        }}
                    >
                        <option value={""}>All </option>

                        <option value={"1"}>Approve</option>
                        <option value={"0"}>Unapprove</option>

                    </select>



                    {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
                </Col>

                <div className="flex1 flex justify-content-end align-items-center">
                    <RangePicker onChange={(date, dateString) => setDate(dateString)} />
                </div>
            </div>

            <div className="container max-w-md mx-auto bg-white rounded shadow-lg p-8">
                <div className="space-y-4">
                    <h2 className="text-lg font-semibold text-gray-800">Expense Summary</h2>
                    <div className="grid grid-cols-1 gap-3 text-gray-800 w-full">
                        <div className="flex justify-between gap-3">
                            <span className="font-bold"><b>Total Ride KM:</b></span>
                            <span>{users_list?.expensestotal?.totalridekm}</span>
                        </div>
                        <div className="flex justify-between gap-3">
                            <span className="font-bold"><b>Bus/Train Fair:</b></span>
                            <span>{users_list?.expensestotal?.bustrainfair}</span>
                        </div>
                        <div className="flex justify-between gap-3">
                            <span className="font-bold"><b>Toll Tax:</b></span>
                            <span>{users_list?.expensestotal?.tolltax}</span>
                        </div>
                        <div className="flex justify-between gap-3">
                            <span className="font-bold"><b>Food:</b></span>
                            <span>{users_list?.expensestotal?.food}</span>
                        </div>
                        <div className="flex justify-between gap-3">
                            <span className="font-bold"><b>Laundry:</b></span>
                            <span>{users_list?.expensestotal?.laundry}</span>
                        </div>
                        <div className="flex justify-between gap-3">
                            <span className="font-bold"><b>Phone Bill:</b></span>
                            <span>{users_list?.expensestotal?.phonebill}</span>
                        </div>
                        <div className="flex justify-between gap-3">
                            <span className="font-bold"><b>Internet Bill:</b></span>
                            <span>{users_list?.expensestotal?.internetbill}</span>
                        </div>
                        <div className="flex justify-between gap-3">
                            <span className="font-bold"><b>Local Conveyance:</b></span>
                            <span>{users_list?.expensestotal?.localconveyance}</span>
                        </div>
                        <div className="flex justify-between gap-3">
                            <span className="font-bold"><b>Other Charges:</b></span>
                            <span>{users_list?.expensestotal?.othercharges}</span>
                        </div>
                    </div>
                </div>
            </div>




            <div className="tableResponsive mt-10">
                <Table
                    className={"table antd_table"}
                    dataSource={listdata}
                    columns={columns}
                    pagination={{
                        current: parseInt(page),
                        pageSize: 10,
                        onChange(current, pageSize) {
                            hendlePahegi(current);
                        },
                        hideOnSinglePage: true,
                        total: users_list?.data?.total ? users_list?.data?.total : 0,
                    }}
                />
            </div>
        </div>
    );
}

export default Expense;
